import { NgModule } from "@angular/core"
import { CommonModule } from "@angular/common"

import { planificacionesRouting } from "./admin-planificaciones.routing"

import { CommonsLayoutsModule } from "@puntaje/shared/layouts"
import { AdminLayoutsModule } from "../layouts"
import { PlanificacionesModule } from "@puntaje/puntaje/new-modules/planificaciones"
import { PlanPersonalesModule } from "@puntaje/puntaje/new-modules/plan-personales"
import { PlanClasesModule } from "@puntaje/puntaje/new-modules/plan-clases"
import { PuntajePlanificacionesModule } from "@puntaje/puntaje/core"

@NgModule({
    declarations: [],
    imports: [
        planificacionesRouting,
        CommonModule,
        AdminLayoutsModule,
        CommonsLayoutsModule,
        PlanificacionesModule,
        PlanPersonalesModule,
        PuntajePlanificacionesModule,
        PlanClasesModule
    ],
    exports: []
})
export class AdminPlanificacionesModule {}
