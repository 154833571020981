import { LoggedLayoutComponent } from "../layouts/logged_layout/logged_layout.component"
import { Routes, RouterModule } from "@angular/router"
import { AuthGuard } from "@puntaje/shared/core"
import { TokenGuard } from "@puntaje/puntaje/services"
import {
    ListaPlanificacionesComponent,
    PlanificacionComponent,
    PlanificacionesEditComponent,
    PlanificacionesNewComponent,
    PlanPersonalContenidoEditComponent,
    PlanPersonalContenidoNewComponent,
    SesionesEditComponent,
    SesionesNewComponent,
    PlanificacionesEstadisticasComponent
} from "@puntaje/puntaje/core"

export const planificacionesRoutes: Routes = [
    {
        path: "planificaciones",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: ListaPlanificacionesComponent, canActivate: [TokenGuard, AuthGuard] }]
    },
    {
        path: "planificaciones/new",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: PlanificacionesNewComponent, canActivate: [TokenGuard, AuthGuard] }]
    },
    {
        path: "planificaciones/:id",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: PlanificacionComponent, canActivate: [TokenGuard, AuthGuard] }]
    },
    {
        path: "planificaciones/:id/edit",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: PlanificacionesEditComponent, canActivate: [TokenGuard, AuthGuard] }]
    },
    {
        path: "planificaciones/:id/sesiones/new",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: SesionesNewComponent, canActivate: [TokenGuard, AuthGuard] }]
    },
    {
        path: "planificaciones/:id/sesiones/:id_session/edit",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: SesionesEditComponent, canActivate: [TokenGuard, AuthGuard] }]
    },
    {
        path: "planificaciones/:id_planificacion/sesiones/:id/contenidos/new",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: PlanPersonalContenidoNewComponent, canActivate: [TokenGuard, AuthGuard] }]
    },
    {
        path: "planificaciones/:id_planificacion/sesiones/:id_session/contenidos/:id/edit",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: PlanPersonalContenidoEditComponent, canActivate: [TokenGuard, AuthGuard] }]
    },
    {
        path: "planificaciones-avance",
        component: LoggedLayoutComponent,
        children: [{ path: "", component: PlanificacionesEstadisticasComponent, canActivate: [TokenGuard, AuthGuard] }]
    }
]

export const planificacionesRouting = RouterModule.forChild(planificacionesRoutes)
