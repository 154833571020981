<div class="contenedor" data-spy="scroll" data-target=".navbar-puntaje" (window:resize)="resizeElements()">
    <div class="mascara">
        <!-- Login -->
        <div class="caja" id="section1">
            <div class="caja-inner-margin">
                <div class="row">
                    <div class="col-md-8 col-md-offset-2">
                        <div class="titulo">
                            <img alt="prelog/logo" src="{{ config.app.assets.logoLargeLight }}" class="landing-logo" />
                        </div>

                        <div class="row">
                            <div class="col-lg-6 col-lg-offset-6 col-md-8 col-md-offset-4 col-sm-8 col-sm-offset-2">
                                <login-puntaje [enableContactUs]="false"></login-puntaje>
                                <br />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
