import { Component, OnInit } from "@angular/core"
import { ActivatedRoute, Router } from "@angular/router"
import { Asignatura, Asignaturas, GeneradorInstrumento, GeneradorInstrumentos } from "@puntaje/nebulosa/api-services"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig
import {
    selectSelectedAsignatura,
    selectSelectedEvaluacionTipo,
    State,
    SetSelectedAsignaturaWithId
} from "@puntaje/puntaje/store"
import { select, Store } from "@ngrx/store"
import { filter } from "rxjs/operators"
import { AuthService } from "@puntaje/shared/core"
import { Subscription, combineLatest } from "rxjs"
import { TitleService } from "@puntaje/shared/core"

@Component({
    selector: "profesores-revisar-planificaciones",
    templateUrl: "./profesores-revisar-planificaciones.component.html",
    styleUrls: ["./profesores-revisar-planificaciones.component.scss"]
})
export class ProfesoresRevisarPlanificacionesComponent implements OnInit {
    asignaturaId: number
    asignatura: Asignatura
    tipoInstrumento: string
    generadorInstrumentos: GeneradorInstrumento[]

    sub: Subscription
    subQuery: Subscription
    subData: Subscription
    evaluacionTipos = ["curricular"]

    asignatura$ = this.store.pipe(
        select(selectSelectedAsignatura),
        filter(x => !!x)
    )

    evaluacionTipo$ = this.store.pipe(
        select(selectSelectedEvaluacionTipo),
        filter(x => !!x)
    )

    constructor(
        protected titleService: TitleService,
        protected asignaturasService: Asignaturas,
        protected route: ActivatedRoute,
        protected router: Router,
        protected generadorInstrumentosService: GeneradorInstrumentos,
        protected store: Store<State>,
        protected authService: AuthService
    ) {}

    ngOnInit() {
        this.titleService.setTitle("titles.core.planificaciones.profesores_revisar_planificaciones")
        this.subQuery = combineLatest(this.asignatura$, this.evaluacionTipo$).subscribe(
            ([asignatura, evaluacionTipo]) => {
                this.asignatura = asignatura
                this.asignaturaId = asignatura.id
                this.tipoInstrumento = evaluacionTipo
                this.getGeneradores()
            }
        )

        this.subData = this.route.data.subscribe(data => {
            this.evaluacionTipos = [data.evaluacionTipo ?? "curricular"]
        })
    }

    getGeneradores() {
        const params = {
            asignatura_plataforma: {
                asignatura_id: this.asignaturaId,
                plataforma_id: config.plataforma.id
            },
            tipo_instrumento: {
                tipo_instrumento: this.tipoInstrumento
            },
            include: "[tipo_instrumento]"
        }

        this.generadorInstrumentosService.where(params).then((generadorInstrumentos: GeneradorInstrumento[]) => {
            this.generadorInstrumentos = generadorInstrumentos
        })
    }

    onAsignaturaIdListener(value: any) {
        this.asignaturaId = value
        this.getGeneradores()
    }

    onReadyAsignatura(asignaturaId) {
        const params = { queryParams: { tipo_instrumento: this.tipoInstrumento, asignatura_id: asignaturaId } }
        this.router.navigate(["/planificaciones/aplicadas"], params)
    }
}
